<template>
  <b-row class="align-items-center justify-content-between">
    <b-col class="col-md-2">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-nowrap mr-1">Показать по</span>
        <b-form-select
          v-model="pagination_data.page_size"
          :options="['3', '5', '10', '20']"
          class="w-auto"
          @change="changePaginationValue"
        />
        <span class="ml-1 text-nowrap"> строк( {{ users_count }} )</span>
      </div>
    </b-col>
    <b-col class="col-md-5 m-auto">
      <BForm
        @submit.prevent="pagination_data.page = inputPage || 1"
      >
        <BInputGroup>
          <BInputGroupPrepend>
            <BButton
              variant="outline-secondary"
            >
              Перейти на стр.
            </BButton>
          </BInputGroupPrepend>
          <BFormInput
            v-model="inputPage"
            :formatter="(v) => $_numberFormatter(v, 10)"
          />
          <BInputGroupAppend>
            <BButton
              variant="success"
              type="submit"
            >
              Выбрать
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BForm>
    </b-col>
    <b-col class="col-md-2">
      <b-pagination
        v-model="pagination_data.page"
        class="m-0"
        :per-page="pagination_data.page_size"
        :total-rows="users_count"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        @input="changePaginationValue"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormSelect, BPagination, BRow, BCol, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
    BPagination,
    BRow,
    BCol,
    BButton,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    users_count: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      inputPage: null,
      pagination_data: {
        page: 1,
        page_size: 10,
      },
    }
  },
  methods: {
    changePaginationValue() {
      this.$emit('changePaginationValue', this.pagination_data)
    },
  },
}
</script>
