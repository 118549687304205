<template>
  <div>
    <b-modal
      id="actionViewModal"
      :ok-title="onEdit ? 'Закрыть' : 'Сохранить'"
      cancel-title="Закрыть"
      centered
      size="md"
      :ok-only="onEdit"
      no-close-on-backdrop
      :ok-variant="onEdit ? 'secondary' : 'success'"
      :title="onEdit ? 'Действия' : 'Редактировать действия'"
      @hide="onEdit = true"
      @show="openShowModal"
      @ok="editData"
    >
      <b-list-group>
        <b-list-group-item class="_flex">
          <span>Дата создания</span>
          <div class="d-flex align-items-center">
            <datepicker
              v-model="item.created_date"
              :clear-button="onEdit ? false : true"
              clear-button-icon="fa fa-times"
              :disabled="onEdit"
              :language="ru"
              format="dd.MM.yyyy"
              :placeholder="onEdit ? '' : 'ДД.ММ.ГГГГ'"
              :input-class="onEdit ? 'onInput' : 'noInput'"
              @input="onSelectCreatedDate"
            />
            <b-button
              class="rounded-circle p-50 ml-25"
              variant="secondary"
              @click="onEdit =! onEdit"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Название</span>
          <b-form-input
            v-model="item.title"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Вид действия</span>
          <VSelect
            v-if="!onEdit"
            v-model="item.action_type"
            label="name"
            class="w-75"
            :reduce="p=>p.id"
            :options="document_types.results"
          />
          <span v-else>
            <div v-if="item.action_type">{{ getActionTypeName }}</div>
          </span>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Комментарий</span>
          <b-form-input
            v-model="item.description"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item
          v-if="onEdit"
          class="_flex"
        >
          <span>Автор</span>
          <span>{{ item.author }}</span>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Контрольная дата</span>
          <datepicker
            v-model="item.planned_time"
            :clear-button="onEdit ? false : true"
            clear-button-icon="fa fa-times"
            :language="ru"
            :disabled="onEdit"
            format="dd.MM.yyyy"
            :placeholder="onEdit ? '' : 'ДД.ММ.ГГГГ'"
            :input-class="onEdit ? 'onInput' : 'noInput'"
            @input="onSelectPlannetDate"
          />
        </b-list-group-item>

        <b-list-group-item
          v-if="onEdit"
          class="_flex"
        >
          <span>Ф.И.О. / Номер телефона должника</span>
          <span class="text-right">
            {{ item.debtor_name }}
          </span>
        </b-list-group-item>

        <b-list-group-item
          v-if="onEdit"
          class="_flex"
        >
          <span>Дата завершения</span>
          <span>{{ item.execution_end }}</span>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Ответственный</span>
          <span
            v-for="(i, indexParticipants) in item.participants"
            :key="indexParticipants"
            class="w-100"
          >
            <VSelect
              v-if="!onEdit"
              v-model="i.name"
              class="w-100"
              label="full_name"
              :options="userList.results"
            />
            <span v-else>{{ i.name }}</span>
          </span>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Вид работ</span>
          <VSelect
            v-if="!onEdit"
            v-model="item.work_type"
            class="w-75"
            label="name"
            :reduce="p=>p.id"
            :options="forms.results"
          />
          <span v-else>
            <div v-if="item.work_type">{{ getWorkTypeName }}</div>
          </span>
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Задание</span>
          <b-form-input
            v-model="item.task_name"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Трек номер</span>
          <b-form-input
            v-model="item.track_number"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Идентификатор</span>
          <b-form-input
            v-model="item.identifier"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Ответ</span>
          <b-form-input
            v-model="item.answer"
            :plaintext="onEdit"
          />
        </b-list-group-item>

        <b-list-group-item class="_flex">
          <span>Дата ответа</span>
          <datepicker
            v-model="item.answer_date"
            :clear-button="onEdit ? false : true"
            clear-button-icon="fa fa-times"
            :disabled="onEdit"
            :language="ru"
            format="dd.MM.yyyy"
            :placeholder="onEdit ? '' : 'ДД.ММ.ГГГГ'"
            :input-class="onEdit ? 'onInput' : 'noInput'"
            @input="onSelectAnswerDate"
          />
        </b-list-group-item>

        <b-list-group-item
          class="_flex "
        >
          <span>Документы</span>
          <div
            v-for="(e, indexFile) in item.files"
            :key="indexFile + 'file'"
            class="text-right"
          >
            <b-button
              v-for="(t, index) in e.files"
              :key="index + 'file'"
              size="sm"
              :disabled="t.file === null"
              :variant="t.file === null ? 'secondary' : 'primary'"
              class="mr-1 position-relative"
              @click="downloadImage(t)"
            >

              <b-badge
                variant="danger"
                class="bbg_t"
                @click.stop="deleteFileData(t.id)"
              >
                x
              </b-badge>
              <span v-if="t.file_title">
                {{ t.file_title }}
              </span>
              <feather-icon
                icon="FileIcon"
              />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <viewFileModal
      :file-data="fileData"
    />
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import { ru } from 'vuejs-datepicker/dist/locale'
import { mapActions, mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import VSelect from 'vue-select'
import moment from 'moment'
import areYouSure from '@/mixins/areYouSure'
import viewFileModal from './viewFile/modal.vue'

export default {
  name: 'ActionTableViewModal',
  components: {
    BModal,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormInput,
    viewFileModal,
    Datepicker,
    VSelect,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [areYouSure],
  props: {
    tableVal: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      ru,
      fileData: {},
      items: [],
      onEdit: true,
      item: {
        title: '',
        action_type: null,
        work_type: {
          name: null,
          id: null,
        },
        planned_time: '',
        participants: '',
        description: '',
        task_name: '',
        track_number: '',
        identifier: '',
        answer: '',
        answer_date: '',
        author: '',
        execution_end: '',
        current_step: '1',
        files: [],
      },
    }
  },
  computed: {
    ...mapState('users', ['userList']),
    ...mapState('reference', ['forms', 'document_types']),
    ...mapState('cases', ['debtListId']),

    getActionTypeName() {
      return this.document_types.results.find(item => item.id === this.tableVal.action_type?.id)?.name
    },

    getWorkTypeName() {
      return this.forms.results.find(e => e.id === this.tableVal.work_type?.id)?.name
    },

  },
  methods: {
    ...mapActions('actions', ['UPDATE_DEBT_ACTION', 'FETCH_DEBT_ACTION']),
    ...mapActions('reference', ['FETCH_WORK_TYPE']),
    ...mapActions('cases', ['DELETE_DEBT_DOCUMENT_FILE_DATA']),

    onSelectCreatedDate(value) {
      if (value) {
        this.item.created_date = moment(value).format('YYYY-MM-DD')
      }
    },

    onSelectPlannetDate(value) {
      if (value) {
        this.item.planned_time = moment(value).format('YYYY-MM-DD')
      }
    },

    onSelectAnswerDate(value) {
      if (value) {
        this.item.answer_date = moment(value).format('YYYY-MM-DD')
      }
    },

    deleteFileData(id) {
      this.$_showAreYouSureModal()
        .then(e => {
          if (e) {
            this.DELETE_DEBT_DOCUMENT_FILE_DATA(id)
              .then(() => {
                this.item.files[0].files = this.item.files[0].files.filter(el => el.id !== id)
              })
          }
        })
    },

    async editData(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (!this.onEdit) {
        const exportData = { ...this.item }
        delete exportData.author
        delete exportData.participants

        await this.UPDATE_DEBT_ACTION({
          id: this.tableVal.id,
          data: {
            content_type: this.debtListId.CONTENT_TYPE,
            current_step: '1',
            participants: this.item.participants?.[0]?.user_id,
            action_type: this.item.action_type?.id,
            work_type: this.item.work_type?.id,
            ...exportData,
          },
        })
        await this.FETCH_DEBT_ACTION({
          content_type: this.debtListId.CONTENT_TYPE,
          object_id: this.$route.params.id,
          current_step: '1',
        })
        this.$bvModal.hide('actionViewModal')
        await this.$_okToast()
        this.onEdit = true
      } else {
        this.$nextTick(() => {
          this.$bvModal.hide('actionViewModal')
        })
      }
    },
    rewriteDate(date) {
      // I was forced because of the date that came to me
      if (date) {
        const rewriteDate = date.split('.')
        const temp = rewriteDate[0]
        // eslint-disable-next-line prefer-destructuring
        rewriteDate[0] = rewriteDate[1]
        rewriteDate[1] = temp
        return rewriteDate.join('.')
      }
      return date
    },

    openShowModal() {
      this.FETCH_WORK_TYPE({ page_size: 1000 })
      this.item.title = this.tableVal.title
      this.item.planned_time = this.rewriteDate(this.tableVal.planned_time)
      this.item.participants = this.tableVal.participants
      this.item.created_date = this.tableVal.created_date
      this.item.description = this.tableVal.description
      this.item.task_name = this.tableVal.task_name
      this.item.track_number = this.tableVal.track_number
      this.item.identifier = this.tableVal.identifier
      this.item.answer = this.tableVal.answer
      this.item.answer_date = this.rewriteDate(this.tableVal.answer_date)
      this.item.files = this.tableVal.file
      this.item.execution_end = this.tableVal.execution_end
      this.item.action_type = this.tableVal.action_type?.id
      this.item.work_type = this.tableVal.work_type?.id
    },

    downloadImage(t) {
      this.fileData = t
      this.$bvModal.show('modalFileView')
    },
  },
}
</script>

<style lang="scss">
._flex{
  display: flex;
  align-items: center;
  justify-content: space-between;

  input{
    text-align: right;
    width: 60%;
  }

  .vdp-datepicker {
    .fa-times {
      TOP: -28px;
      RIGHT: -13px;
    }
  }
}

.onInput{
  border: 0;
  width: 100% !important;
  text-align: right;
  background-color: transparent;
}

.noInput {
  width: 100% !important;
  background-color: #fff;
  outline: none;
  text-align: right;
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.bbg_t {
  position: absolute!important;
  right: -8px!important;
  top: -8px!important;
}
</style>
