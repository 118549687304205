<template>
  <div>
    <div
      id="webviewer"
      ref="viewer"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import WebViewer from '@pdftron/webviewer'

export default {
  name: 'PdfTron',
  props: {
    initialDoc: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const viewer = ref(null)
    onMounted(() => {
      const path = `${process.env.BASE_URL}webviewer`
      WebViewer({ path, initialDoc: props.initialDoc },
        viewer.value)
      // .then(instance => {
      // instance.UI.disableElements(['ribbons'])
      // instance.UI.disableElements(['textPopup'])
      // instance.UI.disableElements(['contextMenuPopup'])
      // instance.UI.disableElements(['freeTextToolButton'])
      // instance.UI.disableElements(['freeHandHighlightToolButton'])
      // instance.UI.disableElements(['freeHandToolButton'])
      // instance.UI.disableElements(['highlightToolButton'])
      // instance.UI.disableElements(['stickyToolButton'])
      //
      // instance.UI.disableElements(['toolsHeader'])
      // instance.UI.disableElements(['downloadButton'])
      // instance.UI.disableElements(['printButton'])
      // instance.UI.disableElements(['languageButton'])
      //   },
      // )
    })

    return { viewer }
  },
}
</script>

<style>
#webviewer {
  height: 800px;
}
</style>
