<template>
  <div>
    <b-modal
      id="modal-acction-edit"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      ok-title="Сохранить"
      ok-variant="success"
      cancel-title="Отменить"
      centered
      size="lg"
      title="Редактировать действия"
      @show="showModal"
      @ok.prevent="onSubmitAct"
    >
      <ValidationObserver ref="editActionValidation">
        <BRow>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Название:"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Название"
                rules="required"
              >
                <BFormInput
                  v-model="formData.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Вид действия"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Вид действия"
                rules="required"
              >
                <VSelect
                  v-model="formData.action_type"
                  label="name"
                  :options="document_types.results"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Вид работ"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Вид работ"
                rules="required"
              >
                <VSelect
                  v-model="formData.work_type"
                  label="name"
                  :options="forms.results"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Контрольная дата"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Контрольная дата"
                rules="required"
              >
                <VcDatePicker
                  v-model="formData.planned_time"
                  show-format="YYYY.MM.DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Ответственный"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Ответственный"
                rules="required"
              >
                <VSelect
                  v-model="formData.participants"
                  label="first_name"
                  :reduce="p => p.id"
                  :options="userList.results"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Комментарий:"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Комментарий"
                rules="required"
              >
                <BFormInput v-model="formData.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Задание:"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Ответственный"
                rules="required"
              >
                <BFormInput
                  v-model="formData.task_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Трек номер:"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Задание"
                rules="required"
              >
                <BFormInput v-model="formData.track_number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol
            class="col-md-6"
          >
            <BFormGroup
              label="Идентификатор:"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Идентификатор"
                rules="required"
              >
                <BFormInput
                  v-model="formData.identifier"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup label="Ответ:">
              <ValidationProvider
                #default="{ errors }"
                name="Ответ"
                rules="required"
              >
                <BFormInput v-model="formData.answer" />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup label="Дата ответа">
              <ValidationProvider
                #default="{ errors }"
                name="Дата ответа"
                rules="required"
              >
                <VcDatePicker
                  v-model="formData.answer_date"
                  show-format="YYYY.MM.DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="col-md-6"
          >
            <BFormGroup label="Документы">
              <BFormFile
                v-model="formData.file"
                placeholder="Файл не выбраны"
                drop-placeholder="Перетащите файлы сюда"
                browse-text="Выбрать"
              />
            </BFormGroup>
          </BCol>

          <b-col>
            <b-form-checkbox
              v-model="formData.is_guarantor"
              checked="true"
              name="check-button"
              switch
              inline
            >Поручитель
            </b-form-checkbox>
          </b-col>
        </BRow>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormFile,
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'Modal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BFormFile,
    VSelect,
    BRow,
    BCol,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editAct: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      formData: {
        title: '',
        action_type: null,
        work_type: null,
        planned_time: '',
        participants: null,
        description: null,
        task_name: null,
        track_number: null,
        identifier: null,
        answer: null,
        answer_date: '',
        file: null,
        task_group: 1,
        is_guarantor: false,
      },
    }
  },
  computed: {
    ...mapState('reference', ['document_types', 'forms']),
    ...mapState('users', ['userList']),
    ...mapState('cases', ['debtListId']),
  },
  methods: {
    ...mapActions('actions', ['UPDATE_DEBT_ACTION', 'FETCH_DEBT_ACTION']),

    onSubmitAct() {
      this.$refs.editActionValidation.validate()
        .then(success => {
          if (success) {
            if (this.editAct.id) {
              const formData = new FormData()
              formData.append('title', this.formData.title)
              if (this.formData.action_type) {
                formData.append('action_type', this.formData.action_type.id)
              }
              if (this.formData.work_type) {
                formData.append('work_type', this.formData.work_type.id)
              }
              formData.append('planned_time', this.formData.planned_time)
              formData.append('participants', this.formData.participants)
              formData.append('description', this.formData.description)
              formData.append('task_name', this.formData.task_name)
              formData.append('track_number', this.formData.track_number)
              formData.append('identifier', this.formData.identifier)
              formData.append('answer', this.formData.answer)
              formData.append('answer_date', this.formData.answer_date)
              formData.append('is_guarantor', this.formData.is_guarantor)
              formData.append('task_group', this.formData.task_group)
              formData.append('current_step', this.editAct.current_step.id)
              if (this.formData.file) {
                formData.append('file', this.formData.file)
              }
              this.UPDATE_DEBT_ACTION({ id: this.editAct.id, data: formData })
                .then(() => {
                  this.FETCH_DEBT_ACTION()
                  this.$bvModal.hide('modal-acction-edit')
                  this.$_okToast()
                })
            } else {
              this.$_errorToast()
              console.error('Bunyodov Dilshod')
            }
          }
        })
    },

    showModal() {
      if (typeof this.editAct === 'object' && this.editAct !== null) {
        this.formData.title = this.editAct.title
        this.formData.action_type = this.editAct.action_type
        this.formData.work_type = this.editAct.work_type
        this.formData.planned_time = this.editAct.planned_time
        this.formData.participants = this.editAct.participants[0]?.user_id
        this.formData.description = this.editAct.description
        this.formData.task_name = this.editAct.task_name
        this.formData.track_number = this.editAct.track_number
        this.formData.answer = this.editAct.answer
        this.formData.answer_date = this.editAct.answer_date
        this.formData.is_guarantor = this.editAct.is_guarantor
        this.formData.identifier = this.editAct.identifier
      }
    },
  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>
