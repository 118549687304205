<template>
  <BCard>
    <BRow>
      <BCol md="3">
        <BFormGroup label="Контрольная дата (с - по)">
          <VcDatePickerVue
            id="dateFrom"
            ref="plan"
            v-model="planned_time"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': true }"
            @input="changeFilter('planned_time', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Дата создания (с - по)">
          <VcDatePickerVue
            id="dateFrom"
            ref="create"
            v-model="created_date"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': true }"
            @input="changeFilter('created_date', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <!-- {{ execution }} -->
        <BFormGroup label="Дата завершения (с - по)">
          <VcDatePickerVue
            id="dateFrom"
            ref="execution"
            v-model="execution"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': true }"
            @input="changeFilter('execution', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Ответственный">
          <VSelect
            v-model="filter.participants"
            :get-option-label="(el) => el.first_name + ' ' + el.last_name"
            :reduce="(p) => p.id"
            :options="userList.results"
            @input="changeFilter('participants', $event)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </VSelect>
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Вид действия">
          <VSelect
            v-model="filter.action_type"
            label="name"
            :reduce="(p) => p.id"
            :options="document_types.results"
            @input="changeFilter('action_type', $event)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </VSelect>
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Статус">
          <VSelect
            v-model="filter.current_step"
            label="title"
            :reduce="(p) => p.value"
            :options="STATUS"
            @input="changeFilter('current_step', $event)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </VSelect>
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Взыскателей">
          <VSelect
            v-model="filter.claimer_crm_company"
            label="COMPANY_NAME"
            :reduce="(p) => p.id"
            :options="claimantsData.results"
            @input="changeFilter('claimer_crm_company', $event)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </VSelect>
        </BFormGroup>
      </BCol>

      <b-col class="col-md-12">
        <div class="d-flex align-items-center justify-content-between">
          <BButton variant="outline-success">
            <feather-icon
              icon="ListIcon"
              class="mr-50"
            />
            <span class="align-middle"> {{ skDebtAction.count }}</span>
          </BButton>
          <div class="d-flex align-items-center px-1">

            <BButton
              class="border ml-1"
              variant="light"
              @click="clearFilter"
            >
              Сбросить фильтры
            </BButton>

            <BButton
              class="d-flex align-items-center ml-1"
              variant="success"
              @click="$emit('excelExport')"
            >
              Скачать в Excel
              <b-spinner
                v-if="loading"
                style="width: 1.5rem; height: 1.5rem"
                class="ml-50"
              />
            </BButton>
          </div>
        </div>
      </b-col>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import VcDatePickerVue from '@/components/VcDatePicker.vue'

import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import saveFilter from '@/mixins/saveFilter'

export default {
  components: {
    BCard,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    VSelect,
    VcDatePickerVue,
    BSpinner,
  },
  mixins: [saveFilter],
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      planned_time: {
        start: null,
        end: null,
      },
      created_date: {
        start: null,
        end: null,
      },
      execution: {
        start: null,
        end: null,
      },
    }
  },

  computed: {
    ...mapState('users', ['userList']),
    ...mapState('actions', ['skDebtAction', 'STATUS']),
    ...mapState('reference', ['document_types', 'claimantsData']),
  },

  async created() {
    const filter = { ...this.$_getFilter() }

    this.planned_time.start = filter.planned_time_from
    this.planned_time.end = filter.planned_time_to
    this.created_date.start = filter.created_date_from
    this.created_date.end = filter.created_date_to
    this.execution.start = filter.execution_end_from
    this.execution.end = filter.execution_end_to

    // eslint-disable-next-line camelcase
    const page_size = {
      page_size: 1000,
    }

    try {
      await this.FETCH_DOCUMENT_TYPES(page_size)
      await this.FETCH_CLAIMANT_TYPE(page_size)
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('actions', ['FETCH_DEBT_ACTION', 'FETCH_DEBT_ACTION_EXCEL']),
    ...mapActions('reference', ['FETCH_DOCUMENT_TYPES', 'FETCH_CLAIMANT_TYPE']),

    changeFilter(type, value) {
      this.$emit('changeFilter', type, value)
    },

    refresh() {
      this.$emit('refresh')
    },

    clearFilter() {
      this.$emit('clearFilter')
      this.planned_time = {
        start: null,
        end: null,
      }
      this.created_date = {
        start: null,
        end: null,
      }
      this.execution = {
        start: null,
        end: null,
      }
      // this.refresh()
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
