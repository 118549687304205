<template>
  <div>
    <b-overlay
      :show="loader"
      rounded="sm"
    >
      <BTable
        :fields="fields"
        :items="skDebtAction.results"
        show-empty
        responsive
        hover
        selected
        class="cursor-pointer"
        @row-clicked="onRowSelected"
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(debtor_name)="props">
          <b-button
            class="text-left text-primary"
            variant="light"
            size="sm"
            @click="onRowSelectedButton(props.item)"
          >
            {{ props.item.debtor_name }}
          </b-button>
        </template>
        <template #cell(check)="row">
          <!--v-model="row.item.check"-->
          <BFormCheckbox
            :checked="checkId.tasks.includes(row.item.id)"
            @change="onPreviewClick($event, row.item.id, row.index)"
          />
        </template>
        <template #cell(participants)="props">
          <span
            v-for="(item, index) in props.item.participants"
            :key="index"
          >
            {{ item.name }}
          </span>
        </template>
        <template #cell(status)="props">
          <b-form-checkbox
            :value="props.item.is_guarantor"
            disabled
            checked="true"
            name="check-button"
            switch
            inline
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="XIcon"
              />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(actions)="props">
          <div class="buttons">
            <BButton
              v-for="(item, index) in props.item.current_step.change_to"
              :key="index"
              v-b-tooltip.hover="{ variant: item.color }"
              :title="item.title"
              size="sm"
              :variant="item.color"
              @click="$emit('onChangeStatus', props, item)"
            >
              <feather-icon :icon="item.icon" />
            </BButton>

            <BButton
              v-if="props.item.current_step.change_to && props.item.current_step.change_to.length"
              v-b-tooltip.hover.v-warning
              title="Изменить"
              variant="warning"
              size="sm"
              @click="editRow(props.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </BButton>
          </div>
        </template>
      </BTable>

      <BRow
        v-if="skDebtAction.results[0]"
        class="mx-1 mb-2"
      >
        <BCol
          md="3"
          class="d-flex align-items-center"
        >
          <BFormCheckbox
            v-model="allChecked"
            @input="checkAll"
          >
            Для всех
          </BFormCheckbox>
        </BCol>
        <BCol
          md="3"
          class="d-flex align-items-end"
        >
          <BFormGroup
            label="Назначить ответственного"
            class="w-100"
          >
            <VSelect
              v-model="checkId.participant"
              label="first_name"
              :reduce="p => p.id"
              :options="userList.results"
            />
          </BFormGroup>
        </BCol>
        <BCol
          md="3"
          class="d-flex align-items-end mb-1"
        >
          <BButton
            variant="primary"
            @click="sendCheckedId"
          >
            Применить
          </BButton>
        </BCol>
      </BRow>
    </b-overlay>
    <actionTableViewModal
      :table-val="tableVal"
    />

    <editModal
      :edit-act="editAct"
    />
  </div>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BFormGroup,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import actionTableViewModal from '@/modules/actions/components/actionTableViewModal.vue'
import empty from '@/components/empty.vue'
import editModal from './editModal/modal.vue'
import FIELDS from './fields'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BOverlay,
    VSelect,
    actionTableViewModal,
    editModal,
    empty,
  },
  props: {
    loader: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      editAct: {},
      checkId: {
        tasks: [],
        participant: null,
      },
      check: false,
      fields: FIELDS.fields,
      tableVal: {},
      allChecked: false,
    }
  },
  computed: {
    ...mapState('actions', ['skDebtAction', 'otherKeys']),
    ...mapState('users', ['userList']),
  },
  created() {
    document.title = 'Действия'
  },
  async mounted() {
    try {
      await this.FETCH_ALL_USERS({ page_size: 1000 })
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('actions', ['FETCH_DEBT_ACTION', 'UPDATE_ACTIONS_CHECKBOX']),
    ...mapActions('users', ['FETCH_ALL_USERS']),

    onRowSelected(params) {
      this.$router.push(`/case-card/${params.object_id}`)
    },

    onRowSelectedButton(item) {
      this.tableVal = item
      this.$nextTick(() => {
        this.$bvModal.show('actionViewModal')
      })
    },

    onPreviewClick(val, id) {
      // if (val) {
      //   this.checkId.tasks.push(id)
      // } else {
      //   this.checkId.tasks.splice(index, 1)
      //   this.checkId = {
      //     tasks: [],
      //     participant: null,
      //   }
      // }

      let replaceIndex
      if (val) {
        for (const key of this.checkId.tasks) {
          if (id > key) {
            replaceIndex = this.checkId.tasks.indexOf(key) + 1
          }
        }
        this.checkId.tasks.splice(replaceIndex, 0, id)
      } else {
        const deleteItemIndex = this.checkId.tasks.indexOf(id)
        this.checkId.tasks.splice(deleteItemIndex, 1)
      }
    },

    async sendCheckedId() {
      if (this.checkId.tasks[0]) {
        await this.UPDATE_ACTIONS_CHECKBOX(this.checkId)
        await this.FETCH_DEBT_ACTION()
        this.checkId = {
          tasks: [],
          participant: null,
        }
        await this.$_okToast()
      }
    },

    checkAll(e) {
      if (e) {
        this.skDebtAction.results.map(i => {
          i.check = this.allChecked
          this.checkId.tasks.push(i.id)
        })
      } else {
        this.allChecked = false
        this.checkId.tasks = []
      }
    },

    editRow(item) {
      this.editAct = item
      this.$nextTick(() => {
        this.$bvModal.show('modal-acction-edit')
      })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.buttons {
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }

  :nth-child(1) {
    margin-left: 0;
  }
}

</style>
