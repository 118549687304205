<template>
  <div>
    <!--    <p id="demo">-->
    <!--      {{ coordinates }}-->
    <!--    </p>-->
    <!--    <button @click="getLocation">-->
    <!--      location-->
    <!--    </button>-->
    <TheFilters
      :filter="filter"
      :loading="loading"
      @excelExport="excelExport"
      @clearFilter="clearFilter"
      @refresh="refresh"
      @changeFilter="changeFilter"
      @setFilterMy="setFilterMy"
    />

    <b-card>
      <TheTable
        :loader="loader"
        @onChangeStatus="onChangeStatus"
      />
    </b-card>

    <b-card>
      <Pagination
        :users_count="skDebtAction.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import saveFilter from '@/mixins/saveFilter'
import { BCard } from 'bootstrap-vue'
import Pagination from '@/components/pagination.vue'
import TheFilters from '../components/TheFilters.vue'
import TheTable from '../components/TheTable.vue'

export default {
  components: {
    TheTable,
    TheFilters,
    BCard,
    Pagination,
  },
  mixins: [saveFilter],
  data() {
    return {
      loader: false,
      loading: false,
      filter: {
        claimer_crm_company: null,
        current_step: null,
        participants: null,
        action_type: null,
        planned_time_from: null,
        planned_time_to: null,
        created_date_from: null,
        created_date_to: null,
        execution_end_from: null,
        execution_end_to: null,
        page: 1,
        page_size: 10,
      },

      planned_time: {
        start: null,
        end: null,
      },
      created_date: {
        start: null,
        end: null,
      },
      execution: {
        start: null,
        end: null,
      },
      // coordinates: '',
    }
  },
  computed: {
    ...mapState('actions', ['skDebtAction']),
  },

  watch: {
    planned_time: {
      handler(val) {
        if (val) {
          this.filter.planned_time_from = val.start
          this.filter.planned_time_to = val.end
        } else {
          this.filter.planned_time_from = null
          this.filter.planned_time_to = null
        }
      },
    },
    created_date: {
      handler(val) {
        if (val) {
          this.filter.created_date_from = val.start
          this.filter.created_date_to = val.end
        } else {
          this.filter.created_date_from = null
          this.filter.created_date_to = null
        }
      },
    },
    execution: {
      handler(val) {
        if (val) {
          this.filter.execution_end_from = val.start
          this.filter.execution_end_to = val.end
        } else {
          this.filter.execution_end_from = null
          this.filter.execution_end_to = null
        }
      },
    },
  },

  async mounted() {
    try {
      this.filter = { ...this.$_getFilter() }

      await this.refresh()
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('actions', ['FETCH_DEBT_ACTION', 'FETCH_DEBT_ACTION_EXCEL', 'UPDATE_ACTIONS']),
    //
    // getLocation() {
    //   const x = document.getElementById('demo')
    //   try {
    //     navigator.geolocation.getCurrentPosition(this.showPosition)
    //   } catch (err) {
    //     x.innerHTML = err
    //   }
    // },
    //
    // showPosition(position) {
    //   this.coordinates = `Latitude: ${
    //     position.coords.latitude
    //   }<br>Longitude: ${
    //     position.coords.longitude}`
    // },

    setFilterMy() {
      const filter = { ...this.$_getFilter() }

      this.planned_time.start = filter.planned_time_from
      this.planned_time.end = filter.planned_time_to
      this.created_date.start = filter.created_date_from
      this.created_date.end = filter.created_date_to
      this.execution.start = filter.execution_end_from
      this.execution.end = filter.execution_end_to
    },

    changePaginationValue({
      page,
      page_size,
    }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      // this.refresh()
      this.FETCH_DEBT_ACTION(this.filter)
    },

    async excelExport() {
      try {
        this.loading = true
        await this.FETCH_DEBT_ACTION_EXCEL({ ...this.filter })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.xls')
            document.body.appendChild(link)
            link.click()
            this.$_okToast()
          })
      } catch (error) {
        await this.$_errorToast()
      }
      this.loading = false
    },

    changeFilter(type, value) {
      this.filter.page = 1

      if (type === 'planned_time') {
        this.filter.planned_time_from = value?.start || ''
        this.filter.planned_time_to = value?.end || ''
      } else if (type === 'created_date') {
        this.filter.created_date_from = value?.start || ''
        this.filter.created_date_to = value?.end || ''
      } else if (type === 'execution') {
        this.filter.execution_end_from = value?.start || ''
        this.filter.execution_end_to = value?.end || ''
      } else {
        this.filter[type] = value
      }

      this.$_saveFilter(this.filter)

      this.refresh()
    },

    clearFilter() {
      sessionStorage.removeItem(this.$route.name)
      this.filter = {
        claimer_crm_company: null,
        current_step: null,
        participants: null,
        action_type: null,
        planned_time_from: null,
        planned_time_to: null,
        created_date_from: null,
        created_date_to: null,
        execution_end_from: null,
        execution_end_to: null,
        page: 1,
        page_size: 10,
      }
      this.planned_time = {
        start: null,
        end: null,
      }
      this.created_date = {
        start: null,
        end: null,
      }
      this.execution = {
        start: null,
        end: null,
      }
    },

    async refresh() {
      const sessionFilter = this.$_getFilter()

      if (sessionFilter) {
        this.filter = { ...sessionFilter }

        this.setFilterMy()
      }

      try {
        this.loader = true
        await this.FETCH_DEBT_ACTION(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.loader = false
    },

    onChangeStatus(props, item) {
      this.$bvModal.msgBoxConfirm(`Вы действительно хотите ${item.title} ?`, {
        headerBgVariant: item.color,
        title: 'Внимание',
        size: 'sm',
        okVariant: item.color,
        okTitle: 'Да',
        cancelTitle: 'Нет',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (!value) return
          await this.UPDATE_ACTIONS({
            current_step: item.id,
            id: props.item.id,
          })
          await this.FETCH_DEBT_ACTION(this.filter)
        })
    },
  },
}
</script>
