export default {
  fields: [
    {
      key: 'check',
      label: '',
    },
    {
      key: 'id',
      label: '№',
    },
    {
      key: 'debtor_name',
      label: 'Ф.И.О. / Номер телефона должника',
    },
    {
      key: 'action_type.name',
      label: 'Вид действия',
    },
    {
      key: 'planned_time',
      label: 'Контрольная дата',
    },
    {
      key: 'execution_start',
      label: 'Дата создания',
    },
    {
      key: 'execution_end',
      label: 'Дата завершения',
    },
    {
      key: 'participants',
      label: 'Ответственный',
    },
    {
      key: 'status',
      label: 'Поручитель',
    },
    {
      key: 'actions',
      label: 'Действия',
    },
  ],
}
